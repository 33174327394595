import React, { FC, useEffect, useRef } from 'react'

import executeScriptTagsFrom from 'components/banner/executeScriptTagsFrom'
import { mambaYandexMetrikaId } from 'components/metric/metric.constant'

/**
 * Скрипт вставки Яндекс Метрики.
 * @constructor
 */
export const YandexMetrikaScript: FC = () => {
  const bannerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    executeScriptTagsFrom(bannerRef.current, 'YandexMetrika')
  }, [])

  return (
    <div
      ref={bannerRef}
      dangerouslySetInnerHTML={{
        __html: `
<script>
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${mambaYandexMetrikaId}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });
</script>
        `,
      }}
    />
  )
}
