import { mambaYandexMetrikaId } from 'components/metric/metric.constant'
import { MetricGoal } from 'components/metric/metric.types'

export const callYandexMetrika = <T>(event: T) => {
  if (window.ym) {
    return window.ym(mambaYandexMetrikaId, MetricGoal.ReachGoal, event)
  }

  return
}
