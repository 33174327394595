export enum MetricEventSend {
  RegistrationEvent = 'reg_event',
  Verification = 'verification',
  CreateContact = 'create_contact',
  EcommercePurchase = 'ecommerce_purchase',
  PhotoUploadSuccess = 'photo_upload_success',
  WebToAppBannerClick = 'webtoapp_click',
}

export enum MetricGoal {
  ReachGoal = 'reachGoal',
}

declare global {
  interface Window {
    ym: <T>(id: number, type: MetricGoal, event: T) => void
  }
}
