import React, { FC, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import {
  resetDefaultAction,
  resetPaymentEventAction,
  sentEventAction,
} from 'actions/metric/metricAction'
import { callYandexMetrika } from 'components/metric/callYandexMetrika'
import { MetricEventSend } from 'components/metric/metric.types'
import { YandexMetrikaScript } from 'components/system/counter/YandexMetrikaScript'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { metricReducer } from 'reducers/metric/metric'

const YandexMetrika: FC = () => {
  useReducersInsert({ metric: metricReducer })
  const dispatch = useDispatch()

  const {
    isNewUser,
    isCreateContact,
    isStartVerification,
    isAlreadySentEventRegistration,
    isAlreadySentEventVerification,
    isAlreadySentEventCreateContact,
    isAlreadySentEventPhotoUpload,
    isSendPaymentEvent,
    authorized,
    realStatusNeeded,
    smallPhoto,
  } = useShallowEqualSelector(
    ({
      metric: {
        isNewUser,
        isCreateContact,
        isStartVerification,
        isAlreadySentEventRegistration,
        isAlreadySentEventVerification,
        isAlreadySentEventCreateContact,
        isAlreadySentEventPhotoUpload,
        isSendPaymentEvent,
      },
      authorizationReducer: { authorized },
      errorReducer: { realStatusNeeded },
      userReducer: { smallPhoto },
    }) => ({
      isNewUser,
      isCreateContact,
      isStartVerification,
      isSendPaymentEvent,
      isAlreadySentEventRegistration,
      isAlreadySentEventVerification,
      isAlreadySentEventCreateContact,
      isAlreadySentEventPhotoUpload,
      authorized,
      realStatusNeeded,
      smallPhoto,
    })
  )

  /**
   * YaMetric
   * Событие регистрации
   */
  useEffect(() => {
    if (isNewUser && !isAlreadySentEventRegistration) {
      callYandexMetrika(MetricEventSend.RegistrationEvent)
      dispatch(sentEventAction(MetricEventSend.RegistrationEvent))
    }
  }, [isNewUser, isAlreadySentEventRegistration, dispatch])

  /**
   * YaMetric
   * Событие Верификаций
   */
  useEffect(() => {
    if (
      isNewUser &&
      authorized &&
      isStartVerification &&
      !realStatusNeeded &&
      !isAlreadySentEventVerification
    ) {
      callYandexMetrika(MetricEventSend.Verification)
      dispatch(sentEventAction(MetricEventSend.Verification))
    }
  }, [
    isNewUser,
    authorized,
    isStartVerification,
    realStatusNeeded,
    isAlreadySentEventVerification,
    dispatch,
  ])

  /**
   * YaMetric
   * Событие отправки сообщение. Первый контакт
   */
  useEffect(() => {
    if (isNewUser && isCreateContact && !isAlreadySentEventCreateContact) {
      callYandexMetrika(MetricEventSend.CreateContact)
      dispatch(sentEventAction(MetricEventSend.CreateContact))
    }
  }, [isNewUser, isCreateContact, isAlreadySentEventCreateContact, dispatch])

  /**
   * YaMetric
   * Событие отправки Фото.
   */
  useEffect(() => {
    if (isNewUser && smallPhoto && !isAlreadySentEventPhotoUpload) {
      callYandexMetrika(MetricEventSend.PhotoUploadSuccess)
      dispatch(sentEventAction(MetricEventSend.PhotoUploadSuccess))
    }
  }, [smallPhoto, isNewUser, isAlreadySentEventPhotoUpload, dispatch])

  /**
   * YaMetric
   * Событие оплаты
   * Пока что vip.
   */
  useEffect(() => {
    if (isSendPaymentEvent) {
      callYandexMetrika(MetricEventSend.EcommercePurchase)
      dispatch(resetPaymentEventAction())
    }
  }, [isSendPaymentEvent, dispatch])

  useEffect(() => {
    if (
      isAlreadySentEventRegistration &&
      isAlreadySentEventVerification &&
      isAlreadySentEventCreateContact &&
      isAlreadySentEventPhotoUpload
    ) {
      dispatch(resetDefaultAction())
    }
  }, [
    isAlreadySentEventRegistration,
    isAlreadySentEventVerification,
    isAlreadySentEventCreateContact,
    isAlreadySentEventPhotoUpload,
    dispatch,
  ])

  return <YandexMetrikaScript />
}

export default YandexMetrika
